/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  grayColor
} from "assets/jss/material-kit-pro-react.jsx";

const typographyStyle = {
  defaultFontStyle: {
    ...defaultFont,
    fontSize: '14px',
  },
  defaultHeaderMargins: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  h1: {
    color: '#263238',
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    color: '#263238',
    fontWeight: 500,
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: '#263238',
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    color: '#263238',
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    color: '#263238',
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    color: '#263238',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: '#263238',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: '#546e7a',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body1: {
    color: '#263238',
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body2: {
    color: '#546e7a',
    fontSize: '12px',
    letterSpacing: '-0.04px',
    lineHeight: '18px',
  },
  button: {
    color: '#263238',
    fontSize: '14px',
  },
  caption: {
    color: '#546e7a',
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  overline: {
    color: '#546e7a',
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
  quote: {
    padding: '10px 20px',
    margin: '0 0 20px',
    fontSize: '1.25rem',
    borderLeft: '5px solid ' + grayColor[2],
  },
  quoteText: {
    margin: '0 0 10px',
    fontStyle: 'italic',
  },
  quoteAuthor: {
    display: 'block',
    fontSize: '80%',
    lineHeight: '1.42857143',
    color: grayColor[10],
  },
  mutedText: {
    '&, & *': {
      color: grayColor[7],
      display: 'inline-block',
    },
  },
  primaryText: {
    '&, & *': {
      color: primaryColor[0],
      display: 'inline-block',
    },
  },
  infoText: {
    '&, & *': {
      color: infoColor[0],
      display: 'inline-block',
    },
  },
  successText: {
    '&, & *': {
      color: successColor[0],
      display: 'inline-block',
    },
  },
  warningText: {
    '&, & *': {
      color: warningColor[0],
      display: 'inline-block',
    },
  },
  dangerText: {
    '&, & *': {
      color: dangerColor[0],
      display: 'inline-block',
    },
  },
  roseText: {
    '&, & *': {
      color: roseColor[0],
      display: 'inline-block',
    },
  },
  smallText: {
    fontSize: '65%',
    fontWeight: '400',
    lineHeight: '1',
    color: grayColor[10],
  },
}

export default typographyStyle;
