import { createMuiTheme } from '@material-ui/core'

import palette from './palette'
import typography from './typography'
import overrides from './overrides'

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  components: {
    MuiButton: {
      styleOverrides: {
        borderRadius: 12,
      },
    },
  },
})

export default theme
