/*eslint-disable*/
import React from "react";
import {connect} from 'react-redux';

import Grid from "@material-ui/core/Grid";

import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import YouTube from 'react-youtube';
import signupPageStyle from "./signupPageStyle.jsx";
import logo from "assets/img/1000x1000.png";
import SfPFooter from "views/Footer.jsx";
import { statuses } from "../../store/signupform/signupReducer.js";
import {
  getCompanyProspect
} from '../../store/signupform/signupActions';
import Button from "../../components/CustomButtons/Button.jsx";

class Success extends React.Component {

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.mute();
  }

  _onEnd(event) {
    event.target.playVideo();
  }

  componentDidMount() {
    const { getCompanyProspect } = this.props;
    const params = new URLSearchParams(this.props.location.search)
    const sessionId = params.get('sessionId')
    getCompanyProspect(sessionId);
  }

  render() {
    const {
      classes,
      fetchCompanyProspectStatus,
      companyProspect
    } = this.props;

    const videoOptions = {
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: 0,
        rel: 0,
        showinfo: 0
      }
    };

    if (fetchCompanyProspectStatus === statuses.FAILED) {
      return null
    }

    return (
      <div>
        <div
          className={classes.pageHeader}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <Card className={classes.cardSignup}>
                  <CardBody>
                    <Grid alignItems="center" container justify="space-between">
                      <Grid className={classes.itemc} item xs={12}>
                        <img className={classes.logo} src={logo} alt="Logo"/>
                      </Grid>
                    </Grid>
                    { fetchCompanyProspectStatus === statuses.LOADING ? (
                      <div className={classes.root}>
                        <div className={classes.cardTitle}><CircularProgress>Loading...</CircularProgress></div>
                      </div>
                    ) : (
                      <div className={classes.root}>
                        <h3 className={classes.cardTitle}>Success!</h3>
                        <div className={classes.successMessages}>
                          <h5>You need to verify your email address before you can log in to your account.</h5>
                          <h5>{`A verification email has been sent to ${companyProspect && companyProspect.email ? companyProspect.email : "your inbox"}.`}</h5>
                        </div>
                        <Grid container alignItems="center" justify="space-between">
                          <Grid className={classes.itemc} item xs={12}>
                            <Button
                              round
                              color="primary"
                              onClick={() => {
                                window.location.href = process.env.REACT_APP_LOGON_APP_URL;
                              }}
                            >
                              Go to Seafoodportal.com
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <SfPFooter/>
        </div>

        <div className="video-background">
          <div className="video-foreground">
            <YouTube
              videoId="mI_SHuTntdc"
              opts={videoOptions}
              className="video-iframe"
              onReady={this._onReady}
              onEnd={this._onEnd}
            />
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetchCompanyProspectStatus: state.signup.fetchCompanyProspectStatus,
  companyProspect: state.signup.companyProspect
});

const mapDispatchToProps = dispatch => ({
  getCompanyProspect: (sessionId) => dispatch(getCompanyProspect(sessionId))
});

export default connect(mapStateToProps, mapDispatchToProps)
(withStyles(signupPageStyle)(Success));
