import * as actionTypes from "./actionTypes";
import * as http from "../../services/http";
import { companyClient } from "../../services/http";
import ReactGA from 'react-ga';

export const load = (url, actionPrefix, payload) => dispatch => {
  const initAction = actionPrefix + actionTypes.LOAD;
  const successAction = actionPrefix + actionTypes.LOADED;
  const failAction = actionPrefix + actionTypes.LOAD_FAILED;

  dispatch({type: initAction, payload});

  return http
    .get(url)
    .then(data => {
      dispatch({
        type: successAction,
        payload: data
      });
      return data;
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: failAction,
        payload: {
          ...payload,
          ...err
        }
      });
      // throw Error(err);
    });
};

export const signUpInit = () => dispatch => {
  dispatch({type: actionTypes.AUTH_SIGN_UP});
};

export const signUpFormUpdate = (key, value) => dispatch => {
  if (key !== undefined) {
    dispatch({
      type: actionTypes.SIGN_UP_FORM_UPDATE,
      key: key,
      value: value
    });
  }
};

export const signUpFormErrorsSet = (data) => dispatch => {
  return dispatch({
    type: actionTypes.SIGN_UP_FORM_ERRORS_SET,
    payload: data
  });
};

export const getCompanyProspect = (sessionId) => dispatch => {
  dispatch({ type: actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOAD})

  companyClient.payments.getCheckoutSession(sessionId)
    .then(response => {
      const res = response.data;
      dispatch({ type: actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOADED, payload: res})
      return res;
    })
    .catch(err =>{
      dispatch({ type: actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOAD_FAILED})
    })
}

export const loadCompanyProspect = (sessionId, step) => dispatch => {
  dispatch({ type: actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOAD})

  companyClient.payments.getCheckoutSession(sessionId)
    .then(response => {
      const res = response.data;
      dispatch({ type: actionTypes.SET_FORM_STATE, payload: {
        form: res,
        step: step
      }})
      dispatch({ type: actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOADED, payload: res})
    })
    .catch(err =>{
      dispatch({ type: actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOAD_FAILED})
    })
}

export const saveCompanyProspect = (data, pageNum) => async dispatch => {
  dispatch({ type: actionTypes.SAVE_COMPANY_PROSPECT + actionTypes.LOAD })

  await companyClient.payments.signup(data)
    .then(response => {
      const res = response.data;
      dispatch({
        type: actionTypes.SAVE_COMPANY_PROSPECT + actionTypes.LOADED,
        payload: res.id
      })
      gaEvent(pageNum, 'Success. Company prospect saved.');
    })
    .catch(err => {
      dispatch({
        type: actionTypes.SIGN_UP_SET_ERROR_BACKEND,
        payload: 'A problem occurred. If the problem continues, contact system administrator.'
      });
      gaEvent(pageNum, 'Failed.  A problem occurred');
    });
}

export const getStripeSession = (data) => async dispatch => {
  dispatch({ type: actionTypes.SAVE_COMPANY_PROSPECT + actionTypes.LOAD });

  console.log({ data });

  const { id, url } = await companyClient.payments
    .signup(data)
    .then(response => {
      const res = response.data;
      dispatch({
        type: actionTypes.SAVE_COMPANY_PROSPECT + actionTypes.LOADED,
        payload: res.id
      });
      gaEvent('3', 'Success. Company prospect saved.');

      return res;
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SIGN_UP_SET_ERROR_BACKEND,
        payload:
          'A problem occurred. If the problem continues, contact system administrator.'
      });
      gaEvent('3', 'Failed.  A problem occurred');

      return undefined;
    });

  if (url) {
    return url;
  }

  if (id) {
    dispatch({ type: actionTypes.GET_STRIPE_SESSION + actionTypes.LOAD })
    
    const url = await companyClient.payments.createCheckoutSession({ id })
      .then(response => {
        const res = response.data;
        dispatch({ 
          type: actionTypes.GET_STRIPE_SESSION + actionTypes.LOADED, 
          payload: res.url 
        })
        gaEvent('3', 'Success. Stripe session initialized.');
  
        return res.url
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SIGN_UP_SET_ERROR_BACKEND,
          payload: 'A problem occurred. If the problem continues, contact system administrator.'
        });
        gaEvent('3', 'Failed.  A problem occurred');
  
        return undefined;
      })
  
    return url;
  } else {
    return undefined;
  }
}

const gaEvent = (step, label) => {
  ReactGA.event({
    category: 'Signup',
    action: 'Step ' + step,
    label: label
  });
};

export const prevStep = (step) => dispatch => {
  dispatch({type: actionTypes.SIGNUP_PREV_STEP});
  gaEvent(step, 'Prev step');
};

export const nextStep = (step) => dispatch => {
  dispatch({type: actionTypes.SIGNUP_NEXT_STEP});
  gaEvent(step, 'Next step');
};
