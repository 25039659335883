/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

import {
  Grid,
  List,
  ListItemText,
  MenuItem,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'

import { companySegmentArray } from '@jetseafoodas/sfp-types'
// import Button from "components/CustomButtons/Button.jsx";
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Joi from 'joi'
import { validateForm } from '../../services/utils'
import theme from 'theme'

const companyTypes = companySegmentArray.sort((a, b) =>
  a.description.localeCompare(b.description)
)

class CompanyTypes extends React.Component {
  validate() {
    const { form, signUpFormErrorsSet } = this.props

    const schema = Joi.object()
      .keys({
        segment: Joi.string()
          .required()
          .messages({ 'any.required': 'Please select your company type' }),
      })
      .options({ allowUnknown: true })

    const result = validateForm(form, schema, (err) => signUpFormErrorsSet(err))
    return result
  }

  render() {
    const { classes, form, formErrors, signUpFormUpdate, nextStep } = this.props

    return (
      <div className={classes.root}>
        <Typography variant="h3" style={{ marginBottom: 15 }} align="center">
          What describes you best?
        </Typography>

        <form className={classes.form}>
          <Grid direction="column" justify="center">
            <Grid
              item
              justify="center"
              style={{
                display: 'flex',
              }}
            >
              <List component="nav" aria-label="secondary mailbox folders">
                {companyTypes.map((ct) => {
                  return (
                    <MenuItem
                      key={ct.id}
                      className={classes.companyType}
                      button
                      onClick={() => {
                        signUpFormUpdate('segment', ct.id)
                      }}
                      selected={form.segment === ct.id}
                    >
                      {form.segment === ct.id ? (
                        <div
                          style={{
                            display: 'flex',
                            position: 'absolute',
                            left: '1em',
                          }}
                        >
                          <DoneIcon />
                        </div>
                      ) : null}
                      <ListItemText primary={ct.description} />
                    </MenuItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item justify="center">
              <Grid container alignItems="center" justify="center">
                <div className={classes.backendErrorMessage}>
                  {formErrors.segment}
                </div>
                <Button
                  round
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    if (this.validate()) {
                      nextStep('1')
                    }
                  }}
                >
                  Step 1 of 3
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Box mt={3}>
                <Typography>
                  Do you have an account?{' '}
                  <a
                    style={{ color: theme.palette.primary.main }}
                    href={process.env.REACT_APP_LOGON_APP_URL}
                  >
                    Login
                  </a>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

CompanyTypes.propTypes = {
  classes: PropTypes.object,
  form: PropTypes.object,
  formErrors: PropTypes.object,
  meta: PropTypes.string,
  signUpFormUpdate: PropTypes.func.isRequired,
  signUpFormErrorsSet: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default CompanyTypes;
