export const validateForm = (form, schema, setErrors) => {
  const result = schema.validate(form, {abortEarly: false});

  if (result.error) {
    const errorObject = {};
    result.error.details.forEach(error => {
      const path = error.path.join(".");
      errorObject[path] = error.message.replace(/["']/g, "");
    });
    setErrors(errorObject);
  } else {
    //no errors found
    setErrors({});
    return true;
  }
};
