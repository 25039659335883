import React from 'react';
import {HashRouter} from 'react-router-dom';
import {Provider as StoreProvider} from 'react-redux';
import {renderRoutes} from 'react-router-config';

import {configureStore} from './store';
import routes from './routes';
import ReactGA from 'react-ga';

import "assets/scss/material-kit-pro-react.scss?v=1.7.0";
import "assets/scss/video.scss";
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

const store = configureStore()
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

const App = () => {
  if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      debug: false,
    })
  }

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <HashRouter>{renderRoutes(routes)}</HashRouter>
      </ThemeProvider>
    </StoreProvider>
  )
}

export default App;
