import axios from "axios";
import { CompanyClient, HttpClientOptions } from "@jetseafoodas/sfp-company-client";

export const axiosInstance = axios.create({
         baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/be-listings/company/v1/`,
         timeout: 60000
       });

const getConfig = () => ({
  headers: {
    'x-access-token': localStorage.getItem('token'),
    Accept: 'application/json'
  }
});

const selectData = response => response.data;

export const get = url => axiosInstance.get(url, getConfig()).then(selectData);
export const post = (url, data) => axiosInstance.post(url, data, getConfig()).then(selectData);
export const put = (url, data) => axiosInstance.put(url, data, getConfig()).then(selectData);
export const del = url => axiosInstance.delete(url, getConfig()).then(selectData);

const options = new HttpClientOptions(axiosInstance, getConfig);
export const companyClient = new CompanyClient(options);
