/*eslint-disable*/
import React, { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { Box, CircularProgress } from '@material-ui/core'

import { connect } from 'react-redux'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import YouTube from 'react-youtube'

import {
  prevStep,
  nextStep,
  signUpInit,
  signUpFormUpdate,
  signUpFormErrorsSet,
  getStripeSession,
  loadCompanyProspect,
} from '../../store/signupform/signupActions'

import signupPageStyle from './signupPageStyle.jsx'
import logo from 'assets/img/1000x1000.png'
import SfPFooter from 'views/Footer.jsx'
import CompanyTypes from './CompanyTypes'
import UserDetails from './UserDetails'
import CompanyDetails from './CompanyDetails'
import { statuses } from '../../store/signupform/signupReducer'
import Topbar from '../Topbar'
import SignUpIllustration from '../../assets/img/signup-illustration.png'

const SignUpPage = (props) => {
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.mute();
  }

  const _onEnd = (event) => {
    event.target.playVideo()
  }

  useEffect(() => {
    const { signUpInit, signUpFormUpdate, loadCompanyProspect } = props

    const legalProductKeys = [
      'SmallMonthly',
      'SmallYearly',
      'MediumMonthly',
      'MediumYearly',
      'EnterpriseMonthly',
      'EnterpriseYearly',
    ]

    const params = new URLSearchParams(props.location.search)
    const productKey = params.get('productKey')
    const sessionId = params.get('sessionId')
    if (legalProductKeys.includes(productKey)) {
      signUpFormUpdate('productKey', productKey)
    }
    if (sessionId) {
      loadCompanyProspect(sessionId, 3)
    }
    signUpInit()
  }, [])

  const {
    classes,
    step,
    form,
    formErrors,
    backendError,
    meta,
    signUpFormUpdate,
    signUpFormErrorsSet,
    prevStep,
    nextStep,
    getStripeSession,
    fetchCompanyProspectStatus,
  } = props

  const videoOptions = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
    },
  }

  //render blank page if no valid product key is provided
  //componentDidMount will validate the productKey before adding it to the form
  if (!form.productKey) {
    return null
  }

  return (
    <Box bgcolor="#FFFFFF">
      <Grid container direction="column" justify="center">
        <Grid item>
          <Topbar />
        </Grid>
        <Grid
          item
          style={{
            backgroundColor: '#FFFFFF',
            marginTop: 64,
            marginBottom: 64,
          }}
        >
          <Grid
            style={{ paddingLeft: 24, paddingRight: 24 }}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item md={4}>
              <img src={SignUpIllustration} alt="Seafood portal" />
            </Grid>
            <Grid item md={7} alignItems="center">
              {fetchCompanyProspectStatus === statuses.LOADING ? (
                // <div className={classes.root}>
                //   <div className={classes.cardTitle}>
                <Box flex={1} justifyContent={'center'} display={'flex'}>
                  <CircularProgress>Loading...</CircularProgress>
                </Box>
              ) : (
                // </Box>
                //   </div>
                // </div>
                <Box>
                  {step === 1 && (
                    <CompanyTypes
                      classes={classes}
                      form={form}
                      formErrors={formErrors}
                      meta={meta}
                      signUpFormUpdate={signUpFormUpdate}
                      signUpFormErrorsSet={signUpFormErrorsSet}
                      nextStep={nextStep}
                    />
                  )}
                  {step === 2 && (
                    <CompanyDetails
                      classes={classes}
                      form={form}
                      formErrors={formErrors}
                      meta={meta}
                      backendError={backendError}
                      signUpFormUpdate={signUpFormUpdate}
                      signUpFormErrorsSet={signUpFormErrorsSet}
                      prevStep={prevStep}
                      nextStep={nextStep}
                    />
                  )}
                  {step === 3 && (
                    <UserDetails
                      classes={classes}
                      form={form}
                      formErrors={formErrors}
                      meta={meta}
                      signUpFormUpdate={signUpFormUpdate}
                      signUpFormErrorsSet={signUpFormErrorsSet}
                      getStripeSession={getStripeSession}
                      prevStep={prevStep}
                      nextStep={nextStep}
                    />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box marginX={'24px'}>
        <SfPFooter />
      </Box>

      {/* <div className="video-background">
        <div className="video-foreground">
          <YouTube
            videoId="mI_SHuTntdc"
            opts={videoOptions}
            className="video-iframe"
            onReady={_onReady}
            onEnd={_onEnd}
          />
        </div>
      </div> */}
    </Box>
  )
}

/*
https://codebushi.com/react-youtube-background/
https://www.youtube.com/watch?v=KKjuRJh_3LY&list=PLGmxyVGSCDKvmLInHxJ9VdiwEb82Lxd2E&index=15
 */

const mapStateToProps = state => ({
  step: state.signup.step,
  form: state.signup.signupForm,
  formErrors: state.signup.signupFormErrors,
  backendError: state.signup.backendError,
  meta: state.signup.meta,
  fetchCompanyProspectStatus: state.signup.fetchCompanyProspectStatus
});

const mapDispatchToProps = dispatch => ({
  signUpInit: () => dispatch(signUpInit()),
  signUpFormUpdate: (key, value) => dispatch(signUpFormUpdate(key, value)),
  signUpFormErrorsSet: (data) => dispatch(signUpFormErrorsSet(data)),
  getStripeSession: (data) => dispatch(getStripeSession(data)),
  prevStep: (step) => dispatch(prevStep(step)),
  nextStep: (step) => dispatch(nextStep(step)),
  loadCompanyProspect: (sessionId, step) => dispatch(loadCompanyProspect(sessionId, step))
});

export default connect(mapStateToProps, mapDispatchToProps)
(withStyles(signupPageStyle)
(SignUpPage));
