
import {
  primaryColor,
  grayColor,
  container,
  cardTitle,
  whiteColor,
  blackColor,
  dangerColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: '2',
    // position: 'relative',
    paddingTop: '2vh',
    color: whiteColor,
    backgroundColor: 'yellow',
    display: 'flex',
  },
  pageHeader: {
    minHeight: '10vh',
    height: 'auto',
    display: 'inherit',
    position: 'relative',
    margin: '0',
    padding: '0',
    border: '0',
    alignItems: 'center',
    '&:before': {
      background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
    },
    '&:after': {
      background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(primaryColor[4]) +
        ',.20),rgba(' +
        hexToRgb(primaryColor[5]) +
        ',.20))',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
  },
  cardSignup: {
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(' +
      hexToRgb(blackColor) +
      ', 0.14), 0 6px 30px 5px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 8px 10px -5px rgba(' +
      hexToRgb(blackColor) +
      ', 0.2);',
    marginBottom: '100px',
    padding: '40px 0px',
  },
  cardTitle: {
    ...cardTitle,
    textDecoration: 'none',
    textAlign: 'center !important',
    marginBottom: '0.75rem',
  },
  cardText: {
    textAlign: 'center !important',
  },
  ...customCheckboxRadioSwitchStyle,
  socials: {
    marginTop: '0',
    position: 'absolute',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
  },
  textCenter: {
    textAlign: 'center',
  },
  inputAdornment: {
    marginRight: '18px',
    position: 'relative',
  },
  inputAdornmentIcon: {
    color: grayColor[13],
  },
  form: {
    margin: '0',
  },
  infoArea: {
    padding: '0px 0px 20px !important',
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  left: {
    float: 'left!important',
    display: 'block',
    '&,& *,& *:hover,& *:focus': {
      color: whiteColor + '  !important',
    },
  },
  right: {
    padding: '15px 0',
    margin: '0',
    float: 'right',
    '&,& *,& *:hover,& *:focus': {
      color: whiteColor + '  !important',
    },
  },
  logo: {
    textAlign: 'center',
    height: '110px',
    marginTop: '30px',
    marginBottom: '30px',
  },
  itemc: {
    textAlign: 'center',
  },
  itemr: {
    textAlign: 'right',
  },
  fields: {
    // margin: '10px',
    flexDirection: 'row',
    display: 'flex',
    // flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      marginBottom: '10px',
      marginLeft: '10px',
    },
    '& .MuiTextField-root': {
      marginTop: '10px',
    },
  },
  icon: {
    width: '18px',
    height: '18px',
    top: '3px',
    position: 'relative',
  },
  companyTypes: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  companyType: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '12px',
    width: '400px',
  },
  errorMessage: {
    color: dangerColor[0],
    fontSize: '13px',
    fontWeight: '400',
    margin: 0,
    marginBottom: '8px',
    marginLeft: '22px',
  },
  backendErrorMessage: {
    flex: 1,
    color: dangerColor[0],
    fontSize: '13px',
    fontWeight: '400',
    margin: 0,
    marginBottom: '20px',
  },
  successMessages: {
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '20px',
  },
}

export default signupPageStyle;
