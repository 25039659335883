/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import SignupPage from './views/SignupPage/SignupPage'
import Success from './views/SignupPage/Success'

const routes = [
  {
    path: '/',
    exact: true,
    component: SignupPage,
  },
  {
    path: '/success',
    exact: true,
    component: Success,
  },
]

export default routes;
