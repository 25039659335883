import React from "react";

import {TextField} from "@material-ui/core";

export const Input = props => {
  const {
    name,
    label,
    value,
    onChange,
    formErrors,
    validate,
    InputProps,
    type
  } = props;

  let error = formErrors && formErrors[name] ? true : false;
  let errorMsg = formErrors && formErrors[name] ? formErrors[name] : null;
  let valueValid = value === null ? "" : value;

  return (
    <TextField
      name={name}
      label={label}
      fullWidth
      error={error}
      helperText={errorMsg}
      type={type}
      InputLabelProps={{ shrink: false }}
      onChange={(val) => {
        onChange(val)
        setTimeout(() => {
          if (error) {
            validate()
          }
        }, 1)
      }}
      value={valueValid}
      inputProps={{ maxLength: 100 }}
      InputProps={InputProps}
      autoComplete="nope"
      variant="outlined"
      style={{ width: '100%', flex: 1 }}
    />
  )
};

export default Input;

