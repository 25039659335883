export const LOAD = "_LOAD";
export const LOADED = "_LOADED";
export const LOAD_FAILED = "_LOAD_FAILED";

export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const AUTH_SIGN_UP_LOADING = "AUTH_SIGN_UP_LOADING";

export const SIGN_UP_FORM_UPDATE = "SIGN_UP_FORM_UPDATE";
export const SIGN_UP_FORM_ERRORS_SET = "SIGN_UP_FORM_ERRORS_SET";
export const SIGN_UP_SET_ERROR_BACKEND = "SIGN_UP_SET_ERROR_BACKEND";
export const SAVE_COMPANY_PROSPECT = "SAVE_COMPANY_PROSPECT";
export const GET_COMPANY_PROSPECT = "GET_COMPANY_PROSPECT";
export const GET_STRIPE_SESSION = "GET_STRIPE_SESSION";
export const SET_FORM_STATE = "SET_FORM_STATE";

export const SIGNUP_NEXT_STEP = "SIGNUP_NEXT_STEP";
export const SIGNUP_PREV_STEP = "SIGNUP_PREV_STEP";
