/*eslint-disable*/
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Check from "@material-ui/icons/Check";

import {
  InputAdornment,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Button,
} from '@material-ui/core'
import Email from '@material-ui/icons/Email'
import Phone from '@material-ui/icons/Phone'

// import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Input from './Input'
import Joi from 'joi'
import { validateForm } from '../../services/utils'
import _ from 'lodash'

import { statuses } from '../../store/signupform/signupReducer'
import theme from 'theme'
import { post } from 'services/http';

const UserDetails = props => {
  const {
    classes,
    form,
    formErrors,
    meta,
    signUpFormUpdate,
    prevStep,
    getStripeSession
  } = props;

  function validate() {
    const { form, signUpFormErrorsSet } = props;

    const schema = Joi.object()
      .keys({
        firstName: Joi.string()
          .required()
          .min(1)
          .max(256)
          .messages({
            'any.required': 'Please enter a first name',
            'string.min': 'Please enter a first name',
            'string.max': 'First name is too long'
          })
          .label('First name'),
        lastName: Joi.string()
          .required()
          .min(1)
          .max(256)
          .messages({
            'any.required': 'Please enter a last name',
            'string.min': 'Please enter a last name',
            'string.max': 'Last name is too long'
          })
          .label('Last name'),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .label('Email'),
        phone: Joi.any()
          .optional()
          .label('Phone'),
        password: Joi.string()
          .pattern(new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$'))
          .required()
          .label('Password')
          .messages({
            'string.pattern.base':
              'Password must be at least 8 characters long and contain at least one upper case letter, one upper case letter, and one number.' //At lease one upper case, one lower case, and one digit. At least 8 chars.
          }),
        passwordConfirm: Joi.string()
          .valid(Joi.ref('password'))
          .required()
          .label('Password confirm')
          .messages({
            'any.required': 'Passwords must match.',
            'any.only': 'Passwords must match'
          }),
        agreeToTerms: Joi.boolean()
          .valid(true)
          .messages({
            'any.only':
              'You must agree to the terms and conditions in order to sign up'
          })
      })
      .options({ allowUnknown: true });

    const result = validateForm(form, schema, err => signUpFormErrorsSet(err));
    return result;
  }

  const submitForm = useCallback(
    _.throttle(
      async () => {
        if (validate()) {
          try {
                // const url = await getStripeSession(form)
                const param = {
                  interestDescription: form.segment,
                  companyName: form.companyName,
                  vatNumber: form.VATNumber,
                  firstName: form.firstName,
                  lastName: form.lastName,
                  email: form.email,
                  phone: form.phone,
                  password: form.password
                };

                await post('security/register', param);

                const url = process.env.REACT_APP_LOGON_APP_URL;

                if (url) {
                  window.location.replace(url);
                }
              } catch (err) {
            console.log(err);
          }
        }
      },
      2000,
      { leading: true, trailing: false }
    ),
    [JSON.stringify(form)]
  );

  return (
    <div className={classes.root}>
      <Typography variant="h3" style={{ marginBottom: 15 }} align="center">
        Your Contact Information
      </Typography>

      <form className={classes.form}>
        <Grid container alignItems="center" direction="column" justify="center">
          <Grid style={{ width: '100%' }} item xs={12} sm={10} md={10}>
            <Grid container direction="column" spacing={2}>
              <Grid item spacing={1}>
                <Box flex={1} display={'flex'} flexDirection={'row'}>
                  <Box flex={1} mr={1} mt={3}>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                      First Name
                      <Typography variant="overline" style={{ color: 'red' }}>
                        *
                      </Typography>
                    </Typography>
                    <Input
                      name="firstName"
                      label={form.firstName ? null : 'Your First name'}
                      value={form.firstName}
                      onChange={e =>
                        signUpFormUpdate(e.target.name, e.target.value)
                      }
                      formErrors={formErrors}
                      validate={() => validate()}
                    />
                  </Box>
                  <Box flex={1} ml={1} mt={3}>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                      Last Name
                      <Typography variant="overline" style={{ color: 'red' }}>
                        *
                      </Typography>
                    </Typography>
                    <Input
                      name="lastName"
                      label={form.lastName ? null : 'Your Last name'}
                      value={form.lastName}
                      onChange={e =>
                        signUpFormUpdate(e.target.name, e.target.value)
                      }
                      formErrors={formErrors}
                      validate={() => validate()}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item spacing={1}>
                <Box flex={1} display={'flex'} flexDirection={'row'}>
                  <Box flex={1} mr={1} mt={3}>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                      Email
                      <Typography variant="overline" style={{ color: 'red' }}>
                        *
                      </Typography>
                    </Typography>
                    <Input
                      name="email"
                      label={form.email ? null : 'Your Email Address'}
                      value={form.email}
                      onChange={e =>
                        signUpFormUpdate(e.target.name, e.target.value)
                      }
                      formErrors={formErrors}
                      validate={() => validate()}
                    />
                  </Box>
                  <Box flex={1} ml={1} mt={3}>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                      Phone
                    </Typography>
                    <Input
                      name="phone"
                      label={form.phone ? null : 'Your Phone Number'}
                      value={form.phone}
                      onChange={e =>
                        signUpFormUpdate(e.target.name, e.target.value)
                      }
                      formErrors={formErrors}
                      validate={() => validate()}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item spacing={1}>
                <Box flex={1} display={'flex'} flexDirection={'row'}>
                  <Box flex={1} mr={1} mt={3}>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                      Password
                      <Typography variant="overline" style={{ color: 'red' }}>
                        *
                      </Typography>
                    </Typography>
                    <Input
                      name="password"
                      label={form.password ? null : 'Create Your Password'}
                      type="password"
                      value={form.password}
                      onChange={e =>
                        signUpFormUpdate(e.target.name, e.target.value)
                      }
                      formErrors={formErrors}
                      validate={() => validate()}
                    />
                  </Box>
                  <Box flex={1} ml={1} mt={3}>
                    <Typography style={{ marginBottom: 10 }} variant="h5">
                      Confirm Password
                      <Typography variant="overline" style={{ color: 'red' }}>
                        *
                      </Typography>
                    </Typography>
                    <Input
                      name="passwordConfirm"
                      label={
                        form.passwordConfirm ? null : 'Re-enter Your Password'
                      }
                      type="password"
                      value={form.passwordConfirm}
                      onChange={e =>
                        signUpFormUpdate(e.target.name, e.target.value)
                      }
                      formErrors={formErrors}
                      validate={() => validate()}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box paddingX={1.5}>
                  <FormControlLabel
                    classes={{
                      label: classes.label
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        name="agreeToTerms"
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        checked={form.agreeToTerms}
                        onChange={() =>
                          signUpFormUpdate('agreeToTerms', !form.agreeToTerms)
                        }
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={
                      <span>
                        I agree to the{' '}
                        <a
                          style={{ color: theme.palette.primary.main }}
                          href="https://www.seafoodportal.com/seafoodportal-terms-and-conditions"
                          target="_blank"
                        >
                          Terms and Conditions
                        </a>
                        .
                      </span>
                    }
                  />
                  <div className={classes.errorMessage}>
                    {formErrors.agreeToTerms}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={10} md={10} style={{ width: '100%' }}>
            <Grid container direction="column">
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={meta !== statuses.READY}
                  color="primary"
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                <Button
                  simple
                  round
                  color="primary"
                  onClick={() => prevStep('1')}
                >
                  &lt;&nbsp;Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

UserDetails.propTypes = {
  classes: PropTypes.object,
  form: PropTypes.object,
  formErrors: PropTypes.object,
  meta: PropTypes.string,
  signUpFormUpdate: PropTypes.func.isRequired,
  signUpFormErrorsSet: PropTypes.func.isRequired,
  getStripeSession: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};

export default UserDetails;
