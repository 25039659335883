import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Button } from '@material-ui/core'
import brandIcon from '../assets/img/logo-efishery.png'
import palette from 'theme/palette'
// import Button from 'components/CustomButtons/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: palette.background.lightGrey,
    padding: 8,
  },
  container: {
    flexGrow: 1,
  },
  ToolbarContainer: {
    flexFlow: 1,
  },
}))

const Topbar = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        position="relative"
      >
        <Toolbar className={classes.ToolbarContainer}>
          <div style={{ flexGrow: 1 }}>
            <RouterLink to="/">
              <img src={brandIcon} alt="Seafood portal" />
            </RouterLink>
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                window.location.href = process.env.REACT_APP_LOGON_APP_URL
              }}
            >
              Login
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
