/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { Grid, Button, Box, Typography } from '@material-ui/core'
import { SingleSelect } from 'react-select-material-ui'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Input from './Input'
import Joi from 'joi'
import { validateForm } from '../../services/utils'

class CompanyDetails extends React.Component {
  validate() {
    const { form, signUpFormErrorsSet } = this.props

    const schema = Joi.object()
      .keys({
        companyName: Joi.string()
          .required()
          .min(1)
          .max(256)
          .messages({
            'any.required': 'Please enter a company name',
            'string.min': 'Please enter a company name',
            'string.max': 'Company name is too long',
          })
          .label('Company name'),
        VATNumber: Joi.number()
          .empty('')
          .optional()
          .label('VAT number'),
        /*companyEmail: Joi.string()
        .email()
        .required()
        .label("Email"),*/
      })
      .options({ allowUnknown: true })

    const result = validateForm(form, schema, (err) => signUpFormErrorsSet(err))
    return result
  }

  render() {
    const {
      classes,
      form,
      formErrors,
      backendError,
      signUpFormUpdate,
      prevStep,
      nextStep,
    } = this.props

    return (
      <div className={classes.root}>
        <form className={classes.form}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid style={{ width: '100%' }} item>
              <Typography align="center" variant="h3">
                Your Company Info
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={10}
              style={{ width: '100%' }}
              direction="row"
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{
                  justifyContent: 'center',
                  marginTop: 16,
                  marginBottom: 16
                }}
              >
                <Grid item style={{ flex: 1 }}>
                  <Typography style={{ marginBottom: 10 }} variant="h5">
                    Company Name
                    <Typography variant="overline" style={{ color: 'red' }}>
                      *
                    </Typography>
                  </Typography>
                  <Input
                    name="companyName"
                    label={form.companyName ? null : 'Your company name'}
                    value={form.companyName}
                    onChange={e =>
                      signUpFormUpdate(e.target.name, e.target.value)
                    }
                    formErrors={formErrors}
                    validate={() => this.validate()}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography style={{ marginBottom: 10 }} variant="h5">
                    VAT Number
                  </Typography>
                  <Input
                    name="VATNumber"
                    label={form.VATNumber ? null : 'Your VAT number'}
                    value={form.VATNumber}
                    onChange={e => {
                      signUpFormUpdate(e.target.name, e.target.value);
                    }}
                    formErrors={formErrors}
                    validate={() => this.validate()}
                  />
                </Grid>
              </Grid>
              {/* <div className={classes.fields}>
                <Input
                  name="companyName"
                  label="Company name"
                  value={form.companyName}
                  onChange={(e) =>
                    signUpFormUpdate(e.target.name, e.target.value)
                  }
                  formErrors={formErrors}
                  validate={() => this.validate()}
                /> */}

              {/*<Input
                  name="companyEmail"
                  label="Company email"
                  value={form.companyEmail}
                  onChange={e => signUpFormUpdate(e.target.name, e.target.value)}
                  formErrors={formErrors}
                  validate={() => this.validate()}
                />*/}
              {/* </div>
              <div className={classes.fields}>
                <Input
                  name="VATNumber"
                  label="VAT number"
                  value={form.VATNumber}
                  onChange={(e) => {
                    signUpFormUpdate(e.target.name, e.target.value)
                  }}
                  formErrors={formErrors}
                  validate={() => this.validate()}
                />
              </div> */}
            </Grid>
            <div className={classes.backendErrorMessage}>{backendError}</div>
            <Grid item xs={12} sm={10} md={10} style={{ width: '100%' }}>
              <Grid container direction="column">
                <Grid item>
                  <Button
                    round
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.validate()) {
                        nextStep('2');
                      }
                    }}
                  >
                    Step 2 of 3
                  </Button>
                </Grid>
                <Grid item>
                  <Box marginY={1}>
                    <Button
                      simple
                      round
                      color="primary"
                      onClick={() => prevStep('2')}
                    >
                      &lt;&nbsp;Back
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

CompanyDetails.propTypes = {
  classes: PropTypes.object,
  form: PropTypes.object,
  formErrors: PropTypes.object,
  meta: PropTypes.string,
  backendError: PropTypes.string,
  signUpFormUpdate: PropTypes.func.isRequired,
  signUpFormErrorsSet: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default CompanyDetails;


