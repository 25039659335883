import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Grid from '@material-ui/core/Grid'
import { colors, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import logo from '../assets/img/Logo-efishery-white.png'
import logoMidtrans from '../assets/img/logo-midtrans.png'
import { ReactComponent as InstagramLogo } from '../assets/svg/instagram.svg'
import { ReactComponent as TiktokLogo } from '../assets/svg/Tiktok.svg'
import { ReactComponent as LinkedinLogo } from '../assets/svg/linkedin.svg'
import { ReactComponent as YoutubeLogo } from '../assets/svg/youtube.svg'
import typographyStyle from 'assets/jss/material-kit-pro-react/components/typographyStyle'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: colors.grey[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  container: {
    marginTop: 24,
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
    '& a': {
      color: colors.grey[500],
    },
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },
  footerBrand: {
    height: '50px',
    padding: '15px 15px',
    fontSize: '14px',
    lineHeight: '50px',
    marginLeft: '-15px',
    color: colors.grey[500],
    textDecoration: 'none',
    fontWeight: 700,
  },
  footerContainer: {
    marginLeft: '-24px',
    marginRight: '-24px',
    marginTop: 16,
    backgroundColor: '#038767',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flex: 1,
  },
  titleTextFooter: {
    color: '#FFFFFF',
  },
  subtitleTextFooter: {
    color: 'rgba(255, 255, 255, 0.75)',
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    height: 1,
    marginBottom: '20px',
  },
  h4: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
}))

function Footer(props) {
  const classes = useStyles()

  return (
    <div className={classes.footerContainer}>
      <Grid container direction="column">
        <Grid item>
          <img src={logo} alt="Seafood portal" />
        </Grid>
        <Grid item>
          <Box mb={1.3}>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={3}>
                <Box marginBottom={2}>
                  <a
                    href="https://efishery.com/"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    <h4 className={classes.h4}>Company</h4>
                  </a>
                </Box>
                <Grid container direction="column">
                  <Grid item>
                    <Box>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        About eFishery
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box marginY={0.5}>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        Blog
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        About Us
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Box marginBottom={2}>
                  <h4 className={classes.h4}>Information</h4>
                </Box>
                <Grid container direction="column">
                  <Grid item>
                    <Box>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        FAQ
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box marginY={0.5}>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        Terms and Conditions
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        Privacy Policy
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Box marginBottom={2}>
                  <h4 className={classes.h4}>Payment Partner</h4>
                </Box>
                <Box>
                  <img src={logoMidtrans} alt="Midtrans payment gateway" />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="fullWidth" className={classes.divider} />
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography
                variant="body1"
                className={classes.subtitleTextFooter}
              >
                ©2023 eFishery | eFishery is a trademark of PT Multidaya
                Teknologi
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={4}>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <InstagramLogo />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        Instagram
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <TiktokLogo />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        Tiktok
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <LinkedinLogo />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        LinkedIn
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <YoutubeLogo />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.subtitleTextFooter}
                      >
                        Youtube
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(typographyStyle)(Footer)
