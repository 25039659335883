import * as actionTypes from "./actionTypes";
import _ from "lodash";

export const statuses = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  READY: "READY",
  FAILED: "FAILED"
};

const initialSignUpFormData = {
  segment: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  companyName: undefined,
  //companyEmail: undefined,
  VATNumber: undefined,
  agreeToTerms: false,
  productKey: 'EnterpriseYearly', //add dummy key to running local
  password: undefined,
  passwordConfirm: undefined
};

const initialState = {
  step: 1,
  signupForm: initialSignUpFormData,
  signupFormErrors: {},
  meta: statuses.IDLE,
  backendError: null,
  id: undefined,
  url: undefined,
  fetchCompanyProspectStatus: statuses.IDLE,
  companyProspect: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOAD: {
      return {
        ...state,
        fetchCompanyProspectStatus: statuses.LOADING,
        meta: statuses.LOADING
      };
    }

    case actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOADED: {
      return {
        ...state,
        fetchCompanyProspectStatus: statuses.READY,
        companyProspect: action.payload,
        meta: statuses.READY
      };
    }

    case actionTypes.GET_COMPANY_PROSPECT + actionTypes.LOAD_FAILED: {
      return {
        ...state,
        fetchCompanyProspectStatus: statuses.FAILED,
        meta: statuses.FAILED
      };
    }

    case actionTypes.SAVE_COMPANY_PROSPECT + actionTypes.LOAD: {
      return {
        ...state,
        meta: statuses.LOADING
      };
    }

    case actionTypes.SAVE_COMPANY_PROSPECT + actionTypes.LOADED: {
      return {
        ...state,
        id: action.payload,
        meta: statuses.READY
      };
    }

    case actionTypes.GET_STRIPE_SESSION + actionTypes.LOAD: {
      return {
        ...state,
        meta: statuses.LOADING
      };
    }

    case actionTypes.GET_STRIPE_SESSION + actionTypes.LOADED: {
      return {
        ...state,
        url: action.payload,
        meta: statuses.READY
      };
    }

    case actionTypes.SET_FORM_STATE: {
      const form = action.payload.form;

      if (!form) {
        return {
          ...state
        }        
      }

      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          segment: form.segment,
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phone: form.phone,
          companyName: form.companyName,
          VATNumber: form.VATNumber,
          agreeToTerms: true,
          productKey: form.productKey,
        },
        step: action.payload.step ? action.payload.step : state.step,
      };
    }

    case actionTypes.SIGN_UP_FORM_UPDATE:
      return {
        ...state,
        signupForm: _.setWith(
          _.clone(state.signupForm),
          action.key,
          action.value,
          _.clone
        )
      };

    case actionTypes.SIGN_UP_FORM_ERRORS_SET:
      return {
        ...state,
        signupFormErrors: action.payload
      };

    case actionTypes.SIGN_UP_SET_ERROR_BACKEND:
      return {
        ...state,
        backendError: action.payload,
        meta: statuses.READY
      };

    case actionTypes.SIGNUP_PREV_STEP:
      return {
        ...state,
        step: state.step > 0 ? state.step - 1 : 1,
        meta: statuses.READY
      };

    case actionTypes.SIGNUP_NEXT_STEP:
      return {
        ...state,
        step: state.step < 4 ? state.step + 1 : 4,
        meta: statuses.READY
      };

    case actionTypes.AUTH_SIGN_UP_LOADING:
      return {
        ...state,
        meta: statuses.LOADING
      };

    default:
      return state;
  }
};
